// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 firebaseConfig : {
    apiKey: "AIzaSyBHgiINCLzaY-YbD0GnsFtP6SexpHnkMdo",
    authDomain: "cdc-nss.firebaseapp.com",
    projectId: "cdc-nss",
    storageBucket: "cdc-nss.appspot.com",
    messagingSenderId: "17429030357",
    appId: "1:17429030357:web:1a6e798f3a9f5b8198895c",
    measurementId: "G-CNTSMEKYFM"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
