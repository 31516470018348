import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard,redirectUnauthorizedTo,redirectLoggedInTo,} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['admin']);



const routes: Routes = [{
  path: '', loadChildren: () => import('./hospital/hospital.module').then(m => m.HospitalModule)  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) ,
  canActivate: [AngularFireAuthGuard],data: { authGuardPipe: redirectLoggedInToHome }},
  {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
   canActivate: [AngularFireAuthGuard],data: { authGuardPipe: redirectUnauthorizedToLogin } }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
